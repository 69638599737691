import React, { useEffect } from 'react';

const defaultLanguage = 'de';
const allowedLanguages = ['de'];

const getBrowserLanguage = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const first = window.navigator.languages
    ? window.navigator.languages[0]
    : null;

  const lang = first ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage;

  return lang;
};

const LanguageRedirectPage = (props) => {
  useEffect(() => {
    let language = getBrowserLanguage() || defaultLanguage;
    if (language.indexOf('-') >= 0) {
      language = language.split('-')[0];
    }
    if (allowedLanguages.indexOf(language) < 0) {
      language = defaultLanguage;
    }

    // In the production environment, paths are prefixed with /smartinvoice.
    let path = props.location.pathname;
    if (path.startsWith('/smartinvoice')) {
      path = path.replace('/smartinvoice', '');
      window.location.href = `${props.location.origin}/smartinvoice/${language}${path}`;
    } else {
      window.location.href = `${props.location.origin}/${language}${path}`;
    }
  }, []);

  return (
    <div>Redirecting you to the correct page...</div>
  )
};

export default LanguageRedirectPage;